import {
  TeamShareStatisticsResponseModel,
  TextTeamShareStatisticsResponseModel,
} from '@capturi/api-insights'
import { useTeams } from '@capturi/core'
import {
  PhoneSegmentState,
  Segment,
  SegmentState,
  TextSegmentState,
} from '@capturi/filters'
import orderBy from 'lodash/orderBy'
import { useMemo } from 'react'

import { TeamSegments } from '../../../../analytics/shared/types'

type Datum = {
  targets: number
  targetsShare: number
  targetsSharePercent: number
  stateIndex: number
}

export type TargetTeamSegmentsData = TeamSegments<Datum>

export type TargetStatistics = {
  targets: number
  targetsShare: number
  targetsSharePercent: number
  teams: {
    teamUid: string
    targets: number
    targetsShare: number
    targetsSharePercent: number
  }[]
}

function convertConversationStatisticsToTargetStatistics(
  conversationStatistics: TeamShareStatisticsResponseModel | null,
): TargetStatistics | null {
  return conversationStatistics
    ? {
        targets: conversationStatistics.conversations,
        targetsShare: conversationStatistics.conversationsShare,
        targetsSharePercent: conversationStatistics.conversationsSharePercent,
        teams: conversationStatistics.teams.map((u) => ({
          teamUid: u.teamUid,
          targets: u.conversations,
          targetsShare: u.conversationsShare,
          targetsSharePercent: u.conversationsSharePercent,
        })),
      }
    : null
}

function convertTextStatisticsToTargetStatistics(
  textStatistics: TextTeamShareStatisticsResponseModel | null,
): TargetStatistics | null {
  return textStatistics
    ? {
        targets: textStatistics.cases,
        targetsShare: textStatistics.casesShare,
        targetsSharePercent: textStatistics.casesSharePercent,
        teams: textStatistics.teams.map((u) => ({
          teamUid: u.teamUid,
          targets: u.cases,
          targetsShare: u.casesShare,
          targetsSharePercent: u.casesSharePercent,
        })),
      }
    : null
}

export function convertHitsToTextTeamItemSegments(
  phoneSegmentStates: PhoneSegmentState[],
  phoneSegments: Segment<TeamShareStatisticsResponseModel>[],
  textSegmentStates: TextSegmentState[],
  textSegments: Segment<TextTeamShareStatisticsResponseModel>[],
  getIndexForState: (state: SegmentState) => number,
): Segment<TargetStatistics>[] {
  const segments = [
    ...phoneSegments.map<Segment<TargetStatistics> & { index: number }>(
      (x, index) => ({
        ...x,
        index: getIndexForState(phoneSegmentStates[index]),
        data: convertConversationStatisticsToTargetStatistics(x.data),
      }),
    ),
    ...textSegments.map<Segment<TargetStatistics> & { index: number }>(
      (x, index) => ({
        ...x,
        index: getIndexForState(textSegmentStates[index]),
        data: convertTextStatisticsToTargetStatistics(x.data),
      }),
    ),
  ]
  // ensure segments are sorted by state.index, important for Datum
  const sortedSegments = segments.sort((a, b) => a.index - b.index)
  return sortedSegments.map(({ index: _, ...segment }) => segment)
}

export function useTeamBreakdownStats(
  segments: Segment<TargetStatistics>[],
): TargetTeamSegmentsData[] {
  const { getTeamByUid } = useTeams()

  return useMemo(() => {
    const allTeamUids = new Set(
      segments.flatMap((s) => (s.data?.teams ?? []).map((x) => x.teamUid)),
    )

    const segmentTeamData = segments.map<{ [key: string]: Datum }>(
      (s, index) => {
        return (s.data?.teams ?? []).reduce<{
          [key: string]: Datum
        }>((acc, x) => {
          acc[x.teamUid] = {
            targets: x.targets,
            targetsShare: x.targetsShare,
            targetsSharePercent: x.targetsSharePercent / 100,
            stateIndex: index,
          }
          return acc
        }, {})
      },
    )

    const segmentData = [...allTeamUids].reduce<{
      [key: string]: TargetTeamSegmentsData
    }>((acc, teamUid) => {
      acc[teamUid] = {
        segments: segments.reduce<Segment<Datum>[]>((acc, s, i) => {
          const map = segmentTeamData[i][teamUid]
          if (map != null) {
            acc.push({
              color: s.color,
              label: s.label,
              data: map ?? {
                targets: 0,
                targetsShare: 0,
                targetsSharePercent: 0,
              },
            })
          }
          return acc
        }, []),
        team: getTeamByUid(teamUid) ?? {
          uid: teamUid,
          name: 'N/A',
          members: [],
          isDeleted: false,
        },
      }
      return acc
    }, {})

    return orderBy(
      Object.values(segmentData),
      [(x) => x.segments[0]?.data?.targetsSharePercent, (x) => x.team.name],
      ['desc', 'asc'],
    )
  }, [segments, getTeamByUid])
}
